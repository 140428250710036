import _ from "lodash"
import React from "react"
import { useMediaQuery } from "react-responsive"
import marked from "marked"
import { parseRawHtml as parse } from "../utils"

import {
  Accordion,
  Card,
  Container,
  Header,
  Image,
  Modal,
  Menu,
  Segment,
  Sidebar,
  Button,
  Grid,
  Responsive,
} from "semantic-ui-react"
import ResponsiveLayout from "../components/responsive-layout"
import SocialShare from "../components/social-share";
import "../components/styles.css"

import PageContent from "frontmatter-markdown-loader?modules!../../content/how-does-your-constitution-compare.md"
import { attributes as interactiveMapsByTopicData } from "frontmatter-markdown-loader?modules!../../content/data/interactive-maps-by-topic.md"
import { attributes as noninteractiveMapsAndFiguresByTopicData } from "frontmatter-markdown-loader?modules!../../content/data/noninteractive-maps-and-figures-by-topic.md"
import { attributes as briefsAndFactsheetsData } from "frontmatter-markdown-loader?modules!../../content/data/briefs-and-fact-sheets.md"

const interactiveMapsByTopic = interactiveMapsByTopicData.topics
const noninteractiveMapsAndFiguresByTopic = noninteractiveMapsAndFiguresByTopicData.topics
const briefsAndFactsheets = briefsAndFactsheetsData.items


export default ({ data = PageContent.attributes }) => (
  <ResponsiveLayout title={data.title}>
    <Page content={data} />
  </ResponsiveLayout>
)

const Page = ({ content, isMobile }) => {
  const isMobileSpecifically = useMediaQuery({
    maxWidth: Responsive.onlyMobile.maxWidth
  })
  const isTabletSpecifically = useMediaQuery({
    minWidth: Responsive.onlyTablet.minWidth,
    maxWidth: Responsive.onlyTablet.maxWidth,
  })
  const isComputerSpecifically = useMediaQuery({
    minWidth: Responsive.onlyTablet.maxWidth
  })
  return (

    <Container>

      <Segment basic id="the-state-of-the-worlds-constitutions" style={{ marginTop: "4em", padding: "0em" }}>

        <Header as="h2" dividing>
          {content.theStateOfTheWorldsConstitutionsSection.title}
          <SocialShare config={content.theStateOfTheWorldsConstitutionsSection.social} float="right" />
        </Header>

        <Segment basic className="MkdContent" style={{ padding: "0em", marginTop: "2em" }}>
          {parse(marked(content.theStateOfTheWorldsConstitutionsSection.introduction))}
        </Segment>

      </Segment>

      <Segment basic id="maps-and-figures" style={{ marginTop: "3em", padding: "0em" }}>

        <Header as="h2" dividing>
          {content.theStateOfTheWorldsConstitutionsSection.mapsAndFiguresSubsection.title}
          <SocialShare config={content.theStateOfTheWorldsConstitutionsSection.mapsAndFiguresSubsection.social} float="right" />
        </Header>

        <Segment basic className="MkdContent" style={{ padding: "0em", marginTop: "2em", marginBottom: "3em" }}>
          {parse(marked(content.theStateOfTheWorldsConstitutionsSection.mapsAndFiguresSubsection.introduction))}
        </Segment>

        {
          isMobile ?
            <Mapsv4 isMobile={isMobile}
              isMobileSpecifically={isMobileSpecifically}
              isTabletSpecifically={isTabletSpecifically}
              isComputerSpecifically={isComputerSpecifically}
            />
            : <Mapsv5 isMobile={isMobile}
              isMobileSpecifically={isMobileSpecifically}
              isTabletSpecifically={isTabletSpecifically}
              isComputerSpecifically={isComputerSpecifically}
            />
        }

      </Segment>

      <Segment basic id="briefs-and-fact-sheets" style={{ marginTop: "4em", padding: "0em" }}>

        <Header as="h2" dividing>
          {content.theStateOfTheWorldsConstitutionsSection.briefsAndFactsheetsSubsection.title}
          <SocialShare config={content.theStateOfTheWorldsConstitutionsSection.briefsAndFactsheetsSubsection.social} float="right" />
        </Header>

        <Segment basic className="MkdContent" style={{ padding: "0em", marginTop: "2em" }}>
          {
            content.theStateOfTheWorldsConstitutionsSection.briefsAndFactsheetsSubsection.introduction &&
            parse(marked(content.theStateOfTheWorldsConstitutionsSection.briefsAndFactsheetsSubsection.introduction))
          }
        </Segment>

        {
          briefsAndFactsheets &&
          <Card.Group centered={isMobile} stackable itemsPerRow={3} style={{ marginTop: "2em" }}>
            {briefsAndFactsheets.map((item, i) => (
              <Card raised style={{ marginTop: "2em" }}>
                <Card.Header as="h3" textAlign="center"
                  style={{
                    margin: "1em",
                    paddingTop: isMobile && "1em"
                  }}
                  content={item.title}
                />
                <Card.Content textAlign="center"
                  content={
                    <>
                      <a target="_blank"
                        href={item.linkedFileSelfHosted || item.linkedFileOther || item.referenceLink}
                        children={
                          <Image bordered centered rounded
                            src={item.image} size="medium"
                          />
                        }
                      />
                      <Button primary as="a" target="_blank"
                        href={item.linkedFileSelfHosted || item.linkedFileOther || item.referenceLink}
                        style={{ marginTop: "1em" }}
                        content="Download"
                      />
                    </>
                  }
                />
              </Card>
            ))}
          </Card.Group>
        }

      </Segment>

    </Container>

  )
}

class Mapsv5 extends React.Component {
  state = {
    contentSegment: this.renderContentSegment(interactiveMapsByTopic[0], 0, noninteractiveMapsAndFiguresByTopic[0])
  }
  toggleTopicVisibility = (topic, index, itemTopic) => () => {
    this.setState({
      contentSegment: this.renderContentSegment(topic, index, itemTopic)
    })
  }
  renderContentSegment(topic, index, itemTopic) {
    return (
      <Segment padded style={{ overflow: "auto", height: "40em" }}>
        <Header as="h3" dividing textAlign="center">{topic.topic.title}</Header>
        <Segment basic>
          <Card.Group itemsPerRow={3} style={{ margin: "0em" }}>
            {topic.topic.interactiveMaps.map(interactiveMap => (
              <Card>
                <Modal closeIcon={true} size="large"
                  trigger={
                    <Image as="a" src={interactiveMap.image} wrapped ui={false} />
                  }
                  children={
                    <>
                      {/* <Modal.Header>
                        {interactiveMap.title}
                        {
                          interactiveMap.referenceLink &&
                          <a href={interactiveMap.referenceLink} target="_blank" style={{ marginLeft: "1em" }}>
                            <Icon size="small" name="external" />
                          </a>
                        }
                      </Modal.Header> */}
                      <Modal.Content>
                        <Segment raised textAlign="center"
                          className={
                            (this.props.isComputerSpecifically && "embed-container")
                            || (this.props.isTabletSpecifically && "tablet-embed-container")
                            || (this.props.isMobileSpecifically && "mobile-embed-container")
                          }
                          style={{
                            marginTop: this.props.isMobileSpecifically && "3em"
                          }}
                          children={parse(interactiveMap.embedTag)}
                        />
                        <Modal.Description>
                          <Segment basic className="MkdContent" style={{ marginTop: "2em", padding: "0em" }}>
                            {interactiveMap.briefDescription && parse(marked(interactiveMap.briefDescription))}
                          </Segment>
                        </Modal.Description>
                      </Modal.Content>
                    </>
                  }
                />
                <Card.Content textAlign="center"
                  style={{
                    marginTop: "1em",
                    borderTop: "0em"
                  }}
                  content={interactiveMap.title}
                />
              </Card>
            )).concat((itemTopic.topic.noninteractiveMapsAndFigures || []).map(item => (
              <Card>
                <Modal closeIcon={true} size="large"
                  trigger={
                    <Image as="a" src={item.image} wrapped ui={false} />
                  }
                  children={
                    <>
                      {/* <Modal.Header>
                        {item.title}
                        {
                          item.referenceLink &&
                          <a href={item.referenceLink} target="_blank" style={{ marginLeft: "1em" }}>
                            <Icon size="small" name="external" />
                          </a>
                        }
                      </Modal.Header> */}
                      <Modal.Content>
                        <Segment raised textAlign="center">
                          <Image bordered centered rounded size="huge" src={item.image} />
                        </Segment>
                        <Modal.Description>
                          <Segment basic className="MkdContent" style={{ marginTop: "2em", padding: "0em" }}>
                            {item.briefDescription && parse(marked(item.briefDescription))}
                          </Segment>
                        </Modal.Description>
                      </Modal.Content>
                    </>
                  }
                />
                <Card.Content textAlign="center"
                  style={{
                    marginTop: "1em",
                    borderTop: "0em",
                  }}
                  content={item.title}
                />
              </Card>
            )))}
          </Card.Group>
        </Segment>
      </Segment>
    )
  }
  render() {
    return (
      <Sidebar.Pushable as={Grid}
        style={{
          margin: "0em"
        }}
        children={
          <>
            <Grid.Column width={2}
              style={{
                padding: "0em",
              }}
              children={
                <Sidebar
                  as={Menu}
                  animation="push"
                  icon="labeled"
                  inverted
                  vertical
                  visible
                  width="thin"
                  style={{
                    backgroundColor: "cadetblue",
                    position: "unset",
                  }}
                  content={
                    interactiveMapsByTopic.map((topic, i) => (
                      <Menu.Item as="a"
                        onClick={this.toggleTopicVisibility(topic, i, noninteractiveMapsAndFiguresByTopic[i])}
                        style={{ lineHeight: "1.5em", textAlign: "left" }}
                        content={topic.topic.title}
                      />
                    ))
                  }
                />
              }
            />
            <Grid.Column width={14}
              style={{
                padding: "0em",
                paddingLeft: "2em",
              }}
              children={
                <Sidebar.Pusher
                  content={this.state.contentSegment}
                />
              }
            />
          </>
        }
      />
    )
  }
}

class Mapsv4 extends React.Component {
  state = {
    activeTopicIndices: {
      0: true
    }
  }
  toggleTopicVisibility = (e, titleProps) => {
    if (this.state.activeTopicIndices[titleProps.index]) {
      delete this.state.activeTopicIndices[titleProps.index]
    }
    else {
      this.state.activeTopicIndices[titleProps.index] = true
    }
    this.setState({
      activeTopicIndices: this.state.activeTopicIndices
    })
  }
  render = () => {
    return (
      <Accordion fluid styled
        style={{ marginTop: "3em" }}
        children={
          interactiveMapsByTopic.map((item, i) => (
            <>
              <Accordion.Title
                index={i}
                // active={!!this.state.activeTopicIndices[i]}
                active={true}
                onClick={this.toggleTopicVisibility}
                icon="dropdown"
                content={item.topic.title}
              />
              <Accordion.Content
                active={!!this.state.activeTopicIndices[i]}
                children={
                  <Segment basic padded={!this.props.isMobile}>
                    <Card.Group stackable itemsPerRow={3} style={{ marginTop: this.props.isMobile && "1em" }}>
                      {
                        item.topic.interactiveMaps
                          .concat(noninteractiveMapsAndFiguresByTopic[i].topic.noninteractiveMapsAndFigures || [])
                          .map(mapOrFig => (
                            <Card centered style={{ padding: "1em", marginTop: "2em" }}>
                              <Modal closeIcon={true} size="large"
                                trigger={
                                  <a>
                                    <Image bordered centered rounded src={mapOrFig.image} style={{ height: !this.props.isMobile && "15em" }} />
                                  </a>
                                }
                                children={
                                  <>
                                    {/* <Modal.Header>
                                    {mapOrFig.title}
                                    {
                                      mapOrFig.referenceLink &&
                                      <a href={mapOrFig.referenceLink} target="_blank" style={{ marginLeft: "1em" }}>
                                        <Icon size="small" name="external" />
                                      </a>
                                    }
                                  </Modal.Header> */}
                                    <Modal.Content>
                                      {
                                        (
                                          mapOrFig.embedTag &&
                                          <Segment raised textAlign="center"
                                            className={
                                              (this.props.isComputerSpecifically && "embed-container")
                                              || (this.props.isTabletSpecifically && "tablet-embed-container")
                                              || (this.props.isMobileSpecifically && "mobile-embed-container")
                                            }
                                            style={{
                                              marginTop: this.props.isMobileSpecifically && "3em"
                                            }}
                                            children={parse(mapOrFig.embedTag)}
                                          />
                                        ) || (
                                          <Segment raised textAlign="center"
                                            style={{
                                              marginTop: this.props.isMobileSpecifically && "3em"
                                            }}
                                            children={
                                              <Image bordered centered rounded size="huge" src={mapOrFig.image} />
                                            }
                                          />
                                        )
                                      }
                                      <Modal.Description>
                                        <Segment basic className="MkdContent" style={{ marginTop: "2em", paddingLeft: "0em" }}>
                                          {mapOrFig.briefDescription && parse(marked(mapOrFig.briefDescription))}
                                        </Segment>
                                      </Modal.Description>
                                    </Modal.Content>
                                  </>
                                }
                              />
                              <Card.Content textAlign="center"
                                style={{
                                  borderTop: "0em",
                                }}
                                content={mapOrFig.title}
                              />
                            </Card>
                          ))
                      }
                    </Card.Group>
                  </Segment>
                }
              />
            </>
          ))
        }
      />
    )
  }
}
